import styled from 'styled-components';

import quoteIcon from 'src/images/quote-icon.svg';

const Container = styled.div`
  background: ${({ theme }) => `var(${theme === 'Dark Theme' ? '--dark' : '--white'})`};
  padding: 0;
  width: 100%;
  
  .clamp--content {
    max-width: 886px;
    padding: 96px 28px;
    position: relative;
    margin: 0 auto;

    &:before {
      content: '';
      position: absolute;
      width: 56px;
      height: 43px;
      background-image: url('${quoteIcon}');
      background-repeat: no-repeat;
      background-size: contain;
      top: 96px;
      left: 50%;
      transform: translateX(-50%);
    }

    @media (max-width: 767px) {
      padding: 20px;

      &:before {
        width: 45px;
        height: 45px;
        top: 20px;
      }
    }
  }

  h1, h5 {
    color: ${({ theme }) => `var(${theme === 'Dark Theme' ? '--white' : '--dark'})`};
    text-align: center;
    margin: 0;
  }

  h5 {
    line-height: var(--line-height-150);
  }

  .clamp--quote {
    margin-top: 83px;

    @media (max-width: 767px) {
      margin-top: 65px;
    }
  }

  .clamp--name {
    margin-top: 40px;

    @media (max-width: 767px) {
      margin-top: 20px;
    }
  }

  .clamp--position-title {
    h5 {
      font-weight: var(--font-weight-400);
    }
  }

  ${({ customCss }) => customCss};
`;

export { Container };
