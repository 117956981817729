import React from 'react';
import ContentfulRichText from 'components/RichText/ContentfulRichText';
import { getEntry } from 'components/Contentful/ContentfulLayout/utils/entriesHelpers';

import { Container } from './styles';

const Clamp = ({ entries = [], options }) => {
  const quote = getEntry('quote', entries);
  const name = getEntry('name', entries);
  const positionTitle = getEntry('position-title', entries);
  const theme = options?.Theme;

  const styles = entries.filter((entry) => entry.templateId === 'section')?.[0]?.styles;
  const customCss = styles?.customStyling?.customStyling;

  return (
    <Container className="clamp--container" theme={theme} customCss={customCss}>
      <div className="clamp--content">
        <ContentfulRichText className="clamp--quote" richText={quote} />
        <ContentfulRichText className="clamp--name" richText={name} />
        <ContentfulRichText className="clamp--position-title" richText={positionTitle} />
      </div>
    </Container>
  );
};

export default Clamp;
